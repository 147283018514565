import React from 'react'; 
import Overlay from './Overlay'; 
import OverlayLoader from './OverlayLoader';
import FormRowInput from './FormRowInput';
import FormRowDropdown from './FormRowDropdown';
import ViaLoopButton from './ViaLoopButton';
import APIButton from '../views/APIButton';

class AddColumnOverlay extends React.Component {

    buttonRow = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 150
    }

    constructor(props) {
        super(props);
        //Set initial state so we don't crash the app 
        this.state = { 
            isDisabled: true,
            loading: false, 
        };
    }

    editColumnName = (event) => {

        //TODO: Make dataIndex and key properly formatted. Make sure they are unique 

        this.setState({
            title: event.target.value, 
            dataIndex: event.target.value, 
            key: event.target.value,
            editable: true,
            sorter: true,
            isDisabled: !this.validateForm()
        });
    }

    dataTypeChanged = (event) => {
        this.setState({dataType: event.target.value}, () => {
            this.setState({
                isDisabled: !this.validateForm()
            });
        });
    }

    //Returns true if form is valid 
    validateForm = () => {

        if (!("title" in this.state)) {
            return false; 
        }

        if (this.state.title.length <= 3) {
            return false; 
        }

        if (this.state.dataType === null || this.state.dataType === "null" || this.state.dataType === undefined) {
            return false; 
        }

        return true; 
    }

    saveCompleted = (response) => {

        this.setState({loading: false});

        const newColumn = this.state;

        if (response instanceof Error) { 
            //An error happened. Let the user know 
            alert("Error adding column. Please reload the page and try again.");
            return; 
        }; 

        this.props.saveClicked(newColumn);
    }

    apiCallInitiated = () => {
        this.setState({loading: true});
    }

    render() {

        if (this.state.loading) {
            return (
                <OverlayLoader 
                    width={600} 
                    height={400} 
                    title="Saving Column"
                />
            );
        }

        const { cancelClicked } = this.props; 

        //post body for the APIButton 
        //Post body must take the form {props : {name1: value1, name2: value2}}
        //This customProps.props MUST be passed by value, otherwise we modify the underlying object and make a big mess 
        let oldColumns = Object.assign({}, this.props.customProps.props);

        oldColumns[this.state.title] = this.state.dataType;

        let postBody = { props: oldColumns };

        return(
            <Overlay
                width={600}
                height={400}
            >
                <h1>Add Column</h1>
               
                <FormRowInput 
                    label="Column Name"
                    onChange={this.editColumnName}
                />
                
                <FormRowDropdown 
                    label="Data Type"
                    onChange={this.dataTypeChanged}
                />

                <div style={this.buttonRow}>
                    <APIButton 
                        title="Save"
                        isDisabled={this.state.isDisabled}
                        callback={ (response) => this.saveCompleted(response) }
                        endpoint={"v1/locations/properties"}
                        body={postBody}
                        method={"POST"}
                        callStart={this.apiCallInitiated}
                    />
                    {/* <ViaLoopButton 
                        title="Save"
                        callback={ () => this.saveColumnName()}
                        isDisabled={this.state.isDisabled}
                    /> */}

                    <ViaLoopButton 
                        title="Cancel"
                        callback={cancelClicked}
                    />
                </div>
                
            </Overlay>
        );
    }

};

export default AddColumnOverlay; 