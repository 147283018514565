import React from 'react';
import Overlay from './Overlay';
import { Spin } from 'antd';

function OverlayLoader(props) {

    const { width, height, title } = props;

    return (
        <Overlay
            width={width}
            height={height}
        >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                <h1>{title}</h1>
                <Spin size="large"/>
            </div>
        </Overlay>
    );
}

export default OverlayLoader; 