import React from 'react'; 

function CovidMapLegend(props) {

    const { showStateSAHO, showCSVStates } = props.legendData;

    const topMargin = 350; 

    if (showStateSAHO) {
        return stateLegend(topMargin); 
    } else if (showCSVStates)  {
        return csvLegend(topMargin); 
    }
   
}
   
export default CovidMapLegend;

function csvLegend(topMargin) {
    const filterBox = {
        backgroundColor: 'white',
        position: 'fixed', 
        top: topMargin, 
        left: 10, 
        width: 170, 
        height: 215, 
        zIndex: 5,
        borderRadius: '0px 0px 5px 5px',
        borderLeft: '2px solid #DB0A5B',
        borderBottom: '2px solid #DB0A5B',
        borderRight: '2px solid #DB0A5B',
        display: 'flex',
        flexDirection: 'column',
    };

    const titleStyle = {
        marginTop: 10,
        marginBottom: 2,
        fontWeight: 'bold',
        fontSize: 18,
    };

    const greenBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const redBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const yellowBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(255, 255, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const orangeBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(255, 165, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const legendRow = {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 13,
        marginTop: 2,
    };

    const legendText = {
        width: 135,
        textAlign: 'left',
        marginLeft: 5,
        marginTop: 10,
    };

    return (

        <div style={filterBox}>

            <p style={titleStyle}>Legend</p>

            <div style={legendRow}>
                <div style={greenBox} />
                <p style={legendText}>Open</p>
            </div>

            <div style={legendRow}>
                <div style={yellowBox} />
                <p style={legendText}>Partial Reopening</p>
            </div>

            <div style={legendRow}>
                <div style={orangeBox} />
                <p style={legendText}>Reopening Soon</p>
            </div>

            <div style={legendRow}>
                <div style={redBox} />
                <p style={legendText}>Shut Down</p>
            </div>
           

        </div>

    );
}

function stateLegend(topMargin) {

    const filterBox = {
        backgroundColor: 'white',
        position: 'fixed', 
        top: topMargin, 
        left: 10, 
        width: 170, 
        height: 150, 
        zIndex: 5,
        borderRadius: '0px 0px 5px 5px',
        borderLeft: '2px solid #DB0A5B',
        borderBottom: '2px solid #DB0A5B',
        borderRight: '2px solid #DB0A5B',
        display: 'flex',
        flexDirection: 'column',
    };

    const titleStyle = {
        marginTop: 10,
        marginBottom: 2,
        fontWeight: 'bold',
        fontSize: 18,
    };

    const greenBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const redBox = {
        height: 25, 
        width: 25,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        border: 'solid black 1px',
        marginLeft: 5,
    };

    const legendRow = {
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 13,
        marginTop: 2,
    };

    const legendText = {
        width: 135,
        textAlign: 'left',
        marginLeft: 5,
        marginTop: 10,
    };

    return (

        <div style={filterBox}>

            <p style={titleStyle}>Legend</p>

            <div style={legendRow}>
                <div style={greenBox} />
                <p style={legendText}>No Govt Order</p>
            </div>

            <div style={legendRow}>
                <div style={redBox} />
                <p style={legendText}>Stay At Home Order In Effect</p>
            </div>
           
        </div>

    );

}
