import React from 'react';

function FormRow(props) {

    const { label, onChange } = props;

    const rowContainer = {
        display: 'flex', 
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10, 
        marginBottom: 10, 
    }

    const inputStyle = {
        width: 400,
    }

    return (
        <div style={rowContainer}>
            {label}: <select style={inputStyle} onChange={onChange}>
                <option value="null">Choose a type...</option>
                <option value="number">Number</option>
                <option value="string">Text less than 255 characters</option>
                <option value="boolean">Boolean</option>
            </select>
        </div>
    );
}

export default FormRow; 