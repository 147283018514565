import React from 'react';
import { useAuth0 } from "../react-auth0-wrapper";

const withAuth0 = Component => props => {
    const auth0 = useAuth0();
    
    return <Component auth0={auth0} {...props}/>
}

export default withAuth0;
