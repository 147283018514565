import React from 'react'; 

function CovidInfoWindow(props) {

    if (props.windowData === null) {
        return null;
    }

    const { data, type } = props.windowData;  

    const lineStyle = {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start'
    };

    const titleStyle = {
        fontWeight: 'bold', 
        marginRight: 5,
    };

    const blockStyle = {
        width: 300, 
    };
   
    if (type === "showCountyPHE" || type === "showCountyIntersection") {
        return generateCountyContent(data, lineStyle, titleStyle, blockStyle);
    } else if (type === "showStateSAHO") {
        return generateStateContent(data, lineStyle, titleStyle, blockStyle);
    } else if (type === "csvDataPolygonType") {
        return generateCSVContent(data, lineStyle, titleStyle, blockStyle);
    }
    
    return <h2>Error: Data Missing</h2>; 
     
}

export default CovidInfoWindow;

function generateCSVContent(data, lineStyle, titleStyle, blockStyle) {

    const status = data.reopeningStatus.replace("_", " ");

    const headerStyle = {
        textTransform: 'capitalize'
    };

    const dataStyle = {
        textTransform: 'capitalize'
    }

    let moreInfo; 
    if (data.resourceLink !== "") {
        moreInfo = (
        <div style={lineStyle}>
            <p style={titleStyle}>More Information: </p>
            <a href={data.resourceLink} target="_blank" rel="noopener noreferrer" >Click Here (opens in new tab)</a>
        </div>
        );
    } 

    let planName; 
    if (data.reopeningPlanName === "") {
        planName = "No Plan Available";
    } else {
        planName = data.reopeningPlanName;
    }

    return (
        <div style={blockStyle}>
            <h2 style={headerStyle}>Status: {status}</h2>

            <div style={lineStyle}>
                <p style={titleStyle}>State: </p>
                <p style={dataStyle}>{data.name}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Entertainment: </p>
                <p style={dataStyle}>{data.entertainmentStatus}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Gyms: </p>
                <p style={dataStyle}>{data.gymStatus}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Personal Care: </p>
                <p style={dataStyle}>{data.personalCareStatus}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Restaurants: </p>
                <p style={dataStyle}>{data.restaurantStatus}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Retail: </p>
                <p style={dataStyle}>{data.retailStatus}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Reopening Plan Name: </p>
                <p style={dataStyle}>{planName}</p>
            </div>

            {moreInfo}
        
        </div>
    );

}

function generateStateContent(data, lineStyle, titleStyle, blockStyle) {

    var inEffect; 

    if (data.YN === "Y") {
        inEffect = "Yes";
    } else {
        inEffect = "No";
    }

    return (
        <div style={blockStyle}>
            <h2>Stay at Home Orders and Advisories by State</h2>

            <div style={lineStyle}>
                <p style={titleStyle}>State: </p>
                <p>{data.STATE}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Order in Effect: </p>
                <p>{inEffect}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Gov't Info: </p>
                <a href={data.f3} target="_blank" rel="noopener noreferrer" >Click Here (opens in new tab)</a>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Effective Date: </p>
                <p>{data.f4}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Expiration Date: </p>
                <p>{data.f5}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Other Notes: </p>
                <p>{data.f6}</p>
            </div>
        
        </div>
    );
}

function generateCountyContent(data, lineStyle, titleStyle, blockStyle) {

    return (
        <div style={blockStyle}>
            <h2>Public Health Emergency Status</h2>

            <div style={lineStyle}>
                <p style={titleStyle}>County Name: </p>
                <p>{data.cnty_name}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>State: </p>
                <p>{data.county_st_long}</p>
            </div>
        
            <div style={lineStyle}>
                <p style={titleStyle}>Local Health Emergency Status: </p>
                <p>{data.local_health_emg}</p>
            </div>

            <div style={lineStyle}>
                <p style={titleStyle}>Square Mileage: </p>
                <p>{data.sqmi}</p>
            </div>
            
            <div style={lineStyle}>
                <p style={titleStyle}>Time Zone: </p>
                <p>{data.time_zone}</p>
            </div>
            
            <div style={lineStyle}>
                <p style={titleStyle}>FEMA Region: </p>
                <p>{data.fema_region}</p>
            </div>
            
            <div style={lineStyle}>
                <p style={titleStyle}>Notes: </p>
                <p>{data.notes}</p>
            </div>
        </div>
    );
}

/*
Shape__Area: 2341948933.82031
Shape__Length: 207259.071080594
chapter: "ARC of Southern Shore NJ"
cnty_name: "Atlantic"
county_st: "NJ"
county_st_long: "New Jersey"
created_date: 1585843770670
created_user: "alow_rconline"
dcode: "D27"
division: "Northeast Division"
dro: null
ecode: "30076"
fema_region: "II"
fips: "34001"
globalid: "b6538866-ddf9-4acb-8ba5-d0db6362c840"
last_edited_date: 1585843770670
last_edited_user: "alow_rconline"
last_update: 1584838933000
local_health_emg: "Red"
name: "Atlantic"
notes: null
objectid: 2806
objectid_1: 2008
rcode: "30R08"
region: "New Jersey Region"
sqmi: 566.69
state_name: "New Jersey"
time_zone: "Eastern"
*/