import React from 'react'; 

const ViaLoopButton = (props) => {

    const { callback, title, isDisabled } = props;

    const buttonStyles = {
        margin: 10, 
        height: 50,
        width: 300,
        color: 'white',
        backgroundColor: '#DB0A5B',
        fontSize: 18,
        fontWeight: 'bold',
    }

    const disabledStyles = {
        margin: 10, 
        height: 50,
        width: 300,
        color: 'white',
        backgroundColor: 'gray',
        fontSize: 18,
        fontWeight: 'bold',
    }

    var button;

    if (isDisabled) {
        button = <button style={disabledStyles} onClick={callback} disabled> {title} </button>;
    } else {
        button = <button style={buttonStyles} onClick={callback}> {title} </button>;
    }

    return button;

}

export default ViaLoopButton;