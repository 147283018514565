
import React from 'react';
import { Table, Input, Popconfirm, Form } from 'antd';
import UpdateLocation from '../models/UpdateLocation';

const EditableContext = React.createContext();

class EditableCell extends React.Component {

    renderCell = ({ getFieldDecorator }) => {

        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editing ? (
                <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            message: `Please Input ${title}!`,
                        },
                    ],
                    initialValue: record[dataIndex],
                    })(<Input />)}
                </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class EditableTable extends React.Component { 

    // linkStyle = {
    //     display: 'flex', 
    //     flexDirection: 'row',
    // }

    constructor(props) {
        super(props);

        //Props are: rows, updateRows 
        const { updateRows } = this.props;
        //updateRows will be used to filter. This is a callback function in the parent component  
        this.updateRows = updateRows; 

        this.state = { editingKey: '' };

        this.auth0 = props.auth0;

    }

    componentDidMount() {

        if (this.props.isEditable) {

            //Edit button code 
            this.props.columns.push({
                title: 'Edit',
                dataIndex: 'operation',
                render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                    <EditableContext.Consumer>
                        {form => (
                            <a
                                onClick={() => this.save(form, record.key)}
                                style={{ marginRight: 8 }}
                            >
                                Save
                            </a>
                        )}
                    </EditableContext.Consumer>
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                        <a>Cancel</a>
                    </Popconfirm>
                    </span>
                ) : (
                    <>
                        <div style={this.linkStyle}>
                            <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}> Edit </a>
                        </div>
                    </>
                );
                },
            });

            //Delete button code 
            // this.props.columns.push({
            //     title: 'Delete',
            //     dataIndex: 'operation',
            //     render: (text, record) => {
            //         return (
            //             <>
            //                 <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(record.key)}>
            //                     <a>Delete</a>
            //                 </Popconfirm>
            //             </>
            //         );
            //     },
            // });

            //This is triggering a re-render after we push the column to this.props.columns. This is SUPER hacky, fix it 
            this.setState({ someStuff: "true" });

            //This places the edit column as the last column in the table 
            // this.columns.push({
            //     title: 'Edit / Delete',
            //     dataIndex: 'operation',
            //     render: (text, record) => {
            //     const { editingKey } = this.state;
            //     const editable = this.isEditing(record);
            //     return editable ? (
            //         <span>
            //         <EditableContext.Consumer>
            //             {form => (
            //                 <a
            //                     onClick={() => this.save(form, record.key)}
            //                     style={{ marginRight: 8 }}
            //                 >
            //                     Save
            //                 </a>
            //             )}
            //         </EditableContext.Consumer>
            //         <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
            //             <a>Cancel</a>
            //         </Popconfirm>
            //         </span>
            //     ) : (
            //         <>
            //             <div style={this.linkStyle}>
            //                 <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}> Edit </a> <a style={{marginLeft: 30}} onClick={ () => this.delete(record.key)}>Delete</a>
            //             </div>
            //         </>
            //     );
            //     },
            // });
        }
    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {

        form.validateFields((error, row) => {
            if (error) {
                return;
            }

            const newData = [...this.props.rows];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                ...item,
                ...row,
                });

                item.customProperties = row;

                this.auth0.getTokenSilently().then( (token) => {
                    UpdateLocation(token, item, this.updateLocationComplete);
                });

                this.setState({ editingKey: '' });
                this.updateRows(newData);
            } else {
                newData.push(row);

                this.setState({ editingKey: '' });
                this.updateRows(newData);
            }
        });
    }

    updateLocationComplete = (jsonObj) => {
        //console.log("Update location complete");
        //console.log(jsonObj);
    }

    edit(key) {
        this.setState({ editingKey: key });
    }

    delete = (key) => {

        //TODO: Make the API call to delete the row
        

        var rows = this.props.rows; 

        var newRows = rows.filter( function(location) {

            if (location.key === key) {
                return false;
            }
            return true;

        });

        this.updateRows(newRows);

    }

    handleTableChange = (pagination, filters, sorter) => {

        //Check to make sure we actually need to filter 
        if (!sorter.hasOwnProperty('column')) {
            return;
        }

        var rows = this.props.rows; 

        rows.sort( (a, b) => { 

            //If a less than b, return negative. If a greater than b, return positive. If equal return 0; 
            if (sorter.order === "ascend") {
                if (sorter.column.dataIndex in a)  {
                    if (sorter.column.dataIndex in b) {
                        return a[sorter.column.dataIndex] > b[sorter.column.dataIndex] ? 1 : -1;
                    } else {
                        //A has value, B is null 
                        return 1;
                    }
                } 

                if (sorter.column.dataIndex in b) {
                    return -1;
                }
            } else {
                if (sorter.column.dataIndex in a) {
                    if (sorter.column.dataIndex in b) {
                        return a[sorter.column.dataIndex] < b[sorter.column.dataIndex] ? 1 : -1;
                    } else {
                        //A has value, B is null, order is descending
                        return -1;
                    }
                } 

                if (sorter.column.dataIndex in b) {
                    return 1;
                }
            }

            return 0;
        });
        
        //Update the rows in the parent component
        this.updateRows(rows);
    }

    onSelectChange = (selectedRowKeys) => {
        this.props.rowSelection(selectedRowKeys);
        this.setState({ selectedRowKeys });
    }

    render() {

        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const { selectedRowKeys } = this.state; 

        const columns = this.props.columns.map(col => {

            if (!col.editable) {
                return col;
            }
            
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'age' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };

        });

        var rowSelection = null; 
        if (this.props.rowSelection) {
            rowSelection = {
                selectedRowKeys: selectedRowKeys,
                onChange: this.onSelectChange,
            } 
        }

        return (
            <EditableContext.Provider value={this.props.form}>
                <Table
                    components={components}
                    bordered
                    dataSource={this.props.rows}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: this.cancel,
                    }}
                    onChange={this.handleTableChange}
                    rowSelection={rowSelection}
                />
            </EditableContext.Provider>
        );
    }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
          