import React from 'react';
import Overlay from './Overlay';
import { Select } from 'antd';
import ViaLoopButton from './ViaLoopButton';
import APIButton from '../views/APIButton';
import OverlayLoader from './OverlayLoader';

const { Option } = Select;

class DeleteColumnOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: false, 
            chosenValue: null, 
            postBody: null, 
        }
    }

    radioContainer = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
        marginLeft: 10,
    }

    buttonContainer = {
        display: 'flex', 
        flexDirection: 'row',
        marginTop: 25,
    }

    chosenValue = "None";

    handleChange = (value) => {

        //Create postBody every time the value changes. 
        var postBody = {props: {}}

        const arrayLength = this.props.columns.length;

        for (var i = 0; i < arrayLength; i++) {

            //Dont append the delete column or the chosen column to the post body
            if (this.props.columns[i].title === "Delete" || this.props.columns[i].title === value) {
                continue; 
            }

            postBody.props[this.props.columns[i].title] = this.props.columns[i].dataType; 

        }

        this.setState({
            chosenValue: value,
            postBody: postBody
        });
    }

    confirmDeletePressed = () => {
        if (this.chosenValue !== "None") {

        }
        this.props.confirmCallback(this.chosenValue);
    }

    cancelPressed = () => {
        this.props.cancelCallback();
    }

    apiCallInitiated = () => {
        this.setState({ isLoading: true });
    }

    deleteCompleted = (response) => {

        //Dismiss the overlay and update columns state in the parent component 
        if (response instanceof Error) { 
            //An error happened. Let the user know 
            alert("Error deleting column. Please reload the page and try again.");
            console.log(response);
            return; 
        }; 

        this.setState({ isLoading: false }, () => {
            this.props.confirmCallback(this.state.chosenValue);
        });
    }

    render() { 

        if (this.state.isLoading) {
            return (
                <OverlayLoader  
                    height={300}
                    width={600}
                    title="Deleting Column"
                />
            );
        }

        var buttonIsDisabled = false; 
        if (this.state.chosenValue === null || this.state.chosenValue === "None") {
            buttonIsDisabled = true; 
        }

        return (
            <Overlay 
                width={600}
                height={300}
            >

                <h1>Choose Column to Delete:</h1>
                <div style={this.radioContainer}>
                    <Select defaultValue="None" style={{ width: 200 }} onChange={this.handleChange}>

                        {this.props.columns.map( (column) => {

                            //The edit/delete column should not appear in the dropdown 
                            if (column.title === "Edit") {
                                return null; 
                            }

                            const option = <Option key={column.title} value={column.title}>{column.title}</Option>

                            return option;

                        })}

                    </Select>
                </div>
                <div style={this.buttonContainer}>
                    {/* <ViaLoopButton 
                        title="Confirm Delete"
                        callback={this.confirmDeletePressed}
                    /> */}
                    <APIButton 
                        title="Confirm Delete"
                        isDisabled={buttonIsDisabled}
                        callback={ (response) => this.deleteCompleted(response) }
                        endpoint={"v1/locations/properties"}
                        body={this.state.postBody}
                        method={"POST"}
                        callStart={this.apiCallInitiated}
                    />
                    <ViaLoopButton 
                        title="Cancel"
                        callback={this.cancelPressed}
                    />
                </div>
            </Overlay>
        );
    }

}

export default DeleteColumnOverlay;