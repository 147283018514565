import React from 'react';
import GetStateIntersection from '../models/GetStateIntersection';
import EditableSortableTable from '../components/EditableSortableTable';
import LoadingScreen from '../components/LoadingScreen';
import { ExportToCsv } from 'export-to-csv';
import { Input } from 'antd';

class CovidTables extends React.Component {

    constructor(props) {
        super(props);

        this.auth0 = props.auth0;

        this.state = {
            columns: columns,
            rows: null,
            allRows: null, 
			isLoading: true, 
        }
        
    }

    componentDidMount() {
        this.auth0.getTokenSilently().then( (token) => {
            GetStateIntersection(token, (statesJSON) => {

                this.convertAPIDataToRows(statesJSON.locations, statesJSON.customProperties);

                //Append columns to table 
                let columns = this.state.columns; 
                Object.keys(statesJSON.customProperties.props).map(function(key, index) {
                    columns.push(
                        {
                            title: key,
                            dataIndex: key,
                            key: key,
                            sorter: true,
                            editable: true, 
                        }
                    );
                    return null; 
                });

                this.setState({columns: columns, isLoading: false});

            });
        });
    }

    convertAPIDataToRows = (rowData, columnData) => {

        var tableRows = [];

        const columnNames = Object.keys(columnData.props); 

        var i; 
        for (i = 0; i < rowData.length; i++) {

            var row = {

				id: rowData[i].location.id,
                key: rowData[i].location.id,
                number: rowData[i].location.number,
                name: rowData[i].location.name,
                address1: rowData[i].location.address1, 
                city: rowData[i].location.city, 
                state: rowData[i].location.state,
                zip: rowData[i].location.zip,
                phone: rowData[i].location.phone,
                status: rowData[i].status,
                entertainment: rowData[i].entertainment,
                gyms: rowData[i].gyms, 
                personalCare: rowData[i].personalCare, 
                restaurants: rowData[i].restaurants,
                retail: rowData[i].retail,  
                reopeningPlanName: rowData[i].planName, 
                resourceLink: rowData[i].resourceLink, 
                orderInEffect: rowData[i].orderInEffect, 
                govtInfo: rowData[i].govtInfo, 
                effectiveDate: rowData[i].effectiveDate,
                expirationDate: rowData[i].expirationDate, 
                otherNotes: rowData[i].otherNotes, 

            };

            //Add null values for the custom column names so the csv export works properly. We want all rows to have at least a null value  
            let j; 
            for (j = 0; j < columnNames.length; j++) {
                row[columnNames[j]] = null; 
            }


            //If we have custom propertes, add them to the row 
            if (rowData[i].location.customProperties !== null) {

                //TODO: Fix this ESLINT warning below 

                Object.keys(rowData[i].location.customProperties).map(function(key, index) {
                    row[key] = rowData[i].location.customProperties[key];
                    return null; 
                });

            }
            
            tableRows.push(row);

        }
		
		this.setState({
            allRows: tableRows,
			rows: tableRows
		});
    }

    exportDataClicked = () => {

		const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'COVID Data Export',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
         
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(this.state.rows);
    }
    
    updateRows = () => {
        //Dont need to do anything here, just have this function declared and ready so the app doesn't crash 
    }

    handleSearch = (value) => {

        var whitespaceCheck = value; 

        whitespaceCheck = whitespaceCheck.trim(); 

        if (whitespaceCheck === "") {
            //Reset search
            var allRows = this.state.allRows;
            this.setState({ rows: allRows });
            return; 
        }
        
        var filteredLocations = this.state.allRows; 

        value = value.toLowerCase();

        filteredLocations = filteredLocations.filter( function(location) {

            /*
                //1. Loop over key, value pairs for each location/row 
                if value for key contains search value, return true 
                if loop completes with no matches found, return false 
            */

            const keys = Object.keys(location);  

            let i; 
            for (i = 0; i < keys.length; i++) {

                const locationValue = location[keys[i]];

                if (locationValue === null || locationValue === undefined || typeof locationValue === "object") {
                    continue;
                }

                if (typeof locationValue === "number") {
                    if (locationValue.toString().toLowerCase().includes(value)) {
                        return true; 
                    }
                    continue;
                }
               
                if (locationValue.toLowerCase().includes(value)) {
                    return true; 
                }
            }

            return false; 
        }); 
        
        this.setState({ 
            rows: filteredLocations,
        });

    }
    
    containerStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }

    tableContainer = {
        overflowX: 'scroll',
        flex: 1, 
    }

    buttonStyle = {
		margin: 15,
		backgroundColor: '#DB0A5B',
		color: 'white',
		fontSize: 18,
		fontWeight: 'bold',
		width: 130,
		height: 50,
    }
    
    headerRow = {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    titleStyle = {
        marginLeft: 10,
        marginTop: 15,
    }

    titleContainer = {
        flex: 1,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        //backgroundColor: 'red',
    }

    searchBar = {
        width: 300,
        margin: 10,
        height: 40, 
    }

    render() {

        if (this.state.isLoading) {
            return (<LoadingScreen />);
        } 

        const { Search } = Input;
        
        return(
            <div style={this.containerStyle}>

                <div style={this.headerRow}>
                    <div style={this.titleContainer}>
                        <h1 style={this.titleStyle}>COVID Tables</h1>
                    </div>
                    <div>
                        <Search 
                            placeholder="input search text"
                            onSearch={value => this.handleSearch(value)}
                            style={this.searchBar}
                        />
                        <button style={this.buttonStyle} onClick={this.exportDataClicked}>Export Data</button>
                    </div>
                </div>

                <div style={this.tableContainer}>
                    <EditableSortableTable 
                        rows={this.state.rows} 
                        columns={this.state.columns}
                        updateRows={this.updateRows}
                        //Note: uncommenting the below will make table rows selectable 
                        //rowSelection={this.onRowSelected}
                    />
                </div>

			</div>
        );
    }
}

export default CovidTables;

const columns = [
    {
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
        sorter: true,
        editable: true, 
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        editable: true,
    },
    {
        title: 'Address',
        dataIndex: 'address1',
        key: 'address1',
        sorter: true,
        editable: true,
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: true, 
        editable: true,
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: true,
        editable: true,
    },
    {
        title: 'Zip',
        dataIndex: 'zip',
        key: 'zip',
        sorter: true,
        editable: true,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
        editable: true,
    },   
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
    },
    {
        title: 'Entertainment',
        dataIndex: 'entertainment',
        key: 'entertainment',
        sorter: true,
    },
    {
        title: 'Gyms',
        dataIndex: 'gyms',
        key: 'gyms',
        sorter: true,
    },
    {
        title: 'Personal Care',
        dataIndex: 'personalCare',
        key: 'personalCare',
        sorter: true,
    },
    {
        title: 'Restaurants',
        dataIndex: 'restaurants',
        key: 'restaurants',
        sorter: true,
    },
    {
        title: 'Retail',
        dataIndex: 'retail',
        key: 'retail',
        sorter: true,
    },
    {
        title: 'Reopening Plan Name',
        dataIndex: 'reopeningPlanName',
        key: 'reopeningPlanName',
        sorter: true,
    },
    {
        title: 'Resource Link',
        dataIndex: 'resourceLink',
        key: 'resourceLink',
        sorter: true,
    },
    {
        title: 'Order In Effect',
        dataIndex: 'orderInEffect',
        key: 'orderInEffect',
        sorter: true,
    },
    {
        title: 'Govt Info',
        dataIndex: 'govtInfo',
        key: 'govtInfo',
        sorter: true,
    },
    {
        title: 'Effective Date',
        dataIndex: 'effectiveDate',
        key: 'effectiveDate',
        sorter: true,
    },
    {
        title: 'Expiration Date',
        dataIndex: 'expirationDate',
        key: 'expirationDate',
        sorter: true,
    },
    {
        title: 'Other Notes',
        dataIndex: 'otherNotes',
        key: 'otherNotes',
        sorter: true,
    },
];

