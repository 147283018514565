import React from 'react'; 
import { Spin } from 'antd';

const LoadingScreen = () => {

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100%',//600
        flex: 1
    }

    return(
        <div style={styles}>
            <h1>Loading Data...</h1>
            <Spin size="large" />
        </div>
        
    );
}

export default LoadingScreen;