import React from 'react';
import { useAuth0 } from "../react-auth0-wrapper";
import { Divider, Typography, Button } from 'antd'

const Login = () => {

    const containerStyle = {
        backgroundColor: '#523178',
        position: 'absolute',
        top: 79,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    }

    const { Title } = Typography;

    const buttonStyle = {
        width: '100%',
        height: 50,
        backgroundColor: '#DB0A5B',
        color: 'white',
        fontSize: 18,
        borderRadius: 5,
        border: 'solid #fffff',
        marginTop: 30,
    }

    const txtStyle = {
        color: 'white'
    }

    const contactStyle = {
        fontSize: 12,
    }


    const { loginWithRedirect, logout } = useAuth0();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let err = params.get('error');

    return (
        <div style={containerStyle}>
            <div> 
                {
                    err ?
                        (
                            <>
                                <h1>Unauthorized</h1>
                                <button
                                    onClick={() =>
                                        logout({ returnTo: `${window.location.origin.toString()}` })
                                    }
                                    style={buttonStyle}
                                >
                                    Logout
                            </button>
                            </>
                        )
                        :
                        (
                            <>
                                <Title style={txtStyle}>Welcome!</Title>
                                <Title level={3} style={txtStyle}>Click below to sign in and get started</Title>
                                <Button
                                    onClick={() =>
                                        loginWithRedirect({ redirect_uri: `${window.location.origin.toString()}/crisisMap` })
                                    }
                                    style={buttonStyle}
                                >
                                    Continue
                            </Button>
                                <Divider dashed />
                                <p style={contactStyle}>Don't have an account? <a href="mailto:info@vialoop.com">Contact Us</a> to get set up.</p>
                            </>
                        )
                }
            </div>
        </div >
    );

}

export default Login; 
