import React from 'react'; 
import SyncLoader from 'react-spinners/SyncLoader';

const LoadingOverlay = () => {

    const styles = {
        position: 'fixed', 
        top: 75, 
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    }

    return(
        <div style={styles}>
            <SyncLoader
          //css={override}
          size={20}
          color={"white"}
          loading={true}
        />
        </div>
        
    );
}

export default LoadingOverlay;