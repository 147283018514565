const GetLocations = (token, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/locations", {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }, 
    }).then( (response) => {
        response.text().then(function (text) {
            try {
                const obj = JSON.parse(text);
                convertToTableRows(obj);
            } catch (e) {
                console.log("Error getting locations:");
                console.log(e);
                console.log("Response text: ", text);
            }
            
        });
    }).catch( function(error) {
        console.log("Error loading data, locations.js 22: ");
        console.log(error);
    });
    

    const convertToTableRows = (jsonResponse) => {

        var tableRows = [];

        var i; 
        for (i = 0; i < jsonResponse.length; i++) {
            
            var row = {
                key: jsonResponse[i].id,
                id: jsonResponse[i].id,
                number: jsonResponse[i].number,
                name: jsonResponse[i].name,
                address1: jsonResponse[i].address1, 
                address2: jsonResponse[i].address2,
                city: jsonResponse[i].city, 
                state: jsonResponse[i].state,
                zip: jsonResponse[i].zip,
                phone: jsonResponse[i].phone
                //customProperties: jsonResponse[i].customProperties
            };

            //Append the custom properties to the row if they exist 
            if (jsonResponse[i].customProperties !== null) {

                let customProperties = jsonResponse[i].customProperties; 
                let entries = Object.entries(customProperties);

                for (const [name, value] of entries) {
                    row[name] = value;
                }
            }
            
            tableRows.push(row);
        }

        callback(tableRows);
    }
  
}

export default GetLocations; 