import React from 'react';

function BasicInfoRow(props) {

    const { label, value } = props;

    const rowContainer = {
        display: 'flex', 
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10, 
        marginBottom: 10, 
    }

    const inputStyle = {
        width: 400,
        textAlign: 'left'
    }

    return (
        <div style={rowContainer}> 
            {label}: <p style={inputStyle}>{value}</p>
        </div>
    );
}

export default BasicInfoRow; 