const UpdateLocation = (token, body, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/locations", {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then( (response) => {
        response.text().then(function (text) {

            //const obj = JSON.parse(text);

            callback(text);

        });
    }).catch( function(error) {
        console.log("Error loading data, UpdateLocation.js 34: ");
        console.log(error);
    });
    
}

export default UpdateLocation; 