import React from 'react';
import LoadingScreen from '../components/LoadingScreen';
import GetCustomerId from '../models/GetCustomerId';

class CrisisMap extends React.Component {

    mapContainer = {
        width: '100%',
        display: 'flex', 
        flexDirection: 'column', 
        flex: 'auto',
    };

    iFrameStyle = {
        height: '100%', 
        width: '100%',
        border: 'none',
    };

    constructor(props) {
        super(props);
        this.auth0 = props.auth0;

        this.state = {
            isLoading: true,
            customerId: "",
            userId: "",
        }
    }

    componentDidMount() {
        this.auth0.getTokenSilently().then( (token) => {
            //Get customer id  
            GetCustomerId(token, (respObj) => {
                this.setState({
                    customerId: respObj.customerId, 
                    userId: respObj.userId,
                    isLoading: false
                });
            });
        });
    }

    getUrlParams = (search) => {
        let hashes = search.slice(search.indexOf('?') + 1).split('&');
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=')
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }

    render() {

        if (this.state.isLoading) {
            return <LoadingScreen />
        }

        var baseURL = ""; 

        if (process.env.REACT_APP_VIALOOP_ENV === "production") {
            baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
        } else {
            baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
        }

        const customerId = this.state.customerId; 
        const userId = this.state.userId;

        let urlInitParam = window.location.href.toString();

        let params = this.getUrlParams(urlInitParam);

        var url = ""
        if (params.init === undefined) {

            url = baseURL + "/viaLoopCrisisMap?k=" + process.env.REACT_APP_VIALOOP_CM_API_KEY + "&cid=" +customerId +"&user=" + userId;
           
        } else {
            url = baseURL + "/viaLoopCrisisMap?k=" + process.env.REACT_APP_VIALOOP_CM_API_KEY + "&cid=" +customerId + "&user=" + userId + "&init=" + params.init;
        }

        return (
            <>

            <div style={this.mapContainer}>
                <iframe title="crisisMap" src={url} style={this.iFrameStyle}></iframe>
            </div>

            </>
        );
    }
}

export default CrisisMap; 