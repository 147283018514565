import React from 'react';

function FormRow(props) {

    const { label, onChange, value } = props;

    const rowContainer = {
        display: 'flex', 
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10, 
        marginBottom: 10, 
    }

    const inputStyle = {
        width: 400,
    }

    return (
        <div style={rowContainer}> 
            {label}: <input 
                style={inputStyle} 
                type="text" 
                onChange={onChange}
                value={value}
            />
        </div>
    );
}

export default FormRow; 