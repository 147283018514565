const GetColumns = (token, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/locations/properties", {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }, 
    }).then( (response) => {
        response.text().then(function (text) {
            const obj = JSON.parse(text);

            callback(obj);

            /*This is what obj looks like: 
            {
                props: {
                    helloWorld: "string"
                    jhlhjkjh: "number"
                    jjkljkljlk: "string"
                }
            }
            */
        });
    }).catch( function(error) {
        console.log("Error loading data, columns.js 22: ");
        console.log(error);
    });
    
}

export default GetColumns; 