
const EnvCheck = () => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    return baseURL;

} 

export default EnvCheck;