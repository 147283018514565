import React from 'react'; 
import logo from '../assets/VL-Logo-White.png';
import { useAuth0 } from "../react-auth0-wrapper";
//import { Mixpanel } from '../models/Mixpanel';
import { Link } from 'react-router-dom';

const NavBar = () => {

    const containerStyle = {
        width: '100%',
        height: 75,
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#523178',
    }

    const linkContainer = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    };

    const buttonStyle = {
        margin: 10, 
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold',
        textDecoration: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
        height: 40,
        borderWidth: 0,
    };

    const linkStyle = {
        margin: 10, 
        color: 'white',
        fontSize: 20,
        //fontWeight: 'bold',
        fontWeight: 900,
        textDecoration: 'none',
    }

    const logoStyle = {
        height: 45,
        marginLeft: 12,
    };

    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return(
        <div style={containerStyle}>
            <img style={logoStyle}  src={logo} alt="Logo"/>
           
            <div style={linkContainer}>
                {!isAuthenticated && (
                    <button
                        style={buttonStyle}
                        onClick={() =>
                            loginWithRedirect({redirect_uri: `${window.location.origin.toString()}/crisisMap`})
                        }
                    >
                        Login
                    </button>
                )}

                {isAuthenticated && (
                    <>

                    <Link to="/settings" style={linkStyle}>Settings</Link>

                    <Link to="/locations" style={linkStyle}>Locations</Link>

                    <Link to="/tableView" style={linkStyle}>Crisis Tables</Link>

                    <Link to="/crisisMap" style={linkStyle}>Crisis Atlas</Link>

                    <Link to="/covidTables" style={linkStyle}>COVID Tables</Link>

                    <Link to="/covidAtlas" style={linkStyle}>COVID Atlas</Link>

                    {/* Remove this feature for now. Maybe add this back in if users show interest later on down the line */}
                    {/* <Link to="/assetMap" style={linkStyle}>Asset Map</Link> */}
                
                    <button 
                        onClick={() => logout({returnTo: `${window.location.origin.toString()}`})}
                        style={buttonStyle}
                    >
                        Logout
                    </button>
                    </>
                )}
            </div>
            
        </div>
    );
    

}

export default NavBar;

