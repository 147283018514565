const GetStateIntersection = (token, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/covidAtlas/stateIntersection", {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }, 
    }).then( (response) => {
        response.text().then(function (text) {

            try {
                const obj = JSON.parse(text);
                callback(obj);
            } catch (e) {
                console.log("Error fetching data: ");
                console.log(e);
                console.log("Text returned: ");
                console.log(text);
            }

            //TODO:  
            /*This is what obj looks like: 
            {
                customProperties: {props: {…}}
                locations: [{…},...],
            }

            */
        });
    }).catch( function(error) {
        console.log("Error loading data, columns.js 22: ");
        console.log(error);
    });
    
}

export default GetStateIntersection; 
