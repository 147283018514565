import React from 'react';
import { Map, Polygon, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MarkerClusterer from '@google/markerclustererplus';
import GetCountyEmergencyData from '../models/GetCountyEmergencyData';
import GetIntersectedCountyEmergencyData from '../models/GetIntersectedCountyEmergencyData';
import GetStateStayAtHomeOrders from '../models/GetStateStayAtHomeOrders';
import CovidMapLayers from '../components/CovidMapLayers';
import CovidInfoWindow from '../components/CovidPolygonInfoWindow';
import CovidMapLegend from '../components/CovidMapLegend';
import MapStyle from '../views/MapStyles';
import LoadingOverlay from '../components/LoadingOverlay';

import MarkerIcon from '../assets/black_pink_marker.png';
import ClusterIcon from '../assets/1_pink.png';

import MarkerInfoWindowContent from '../components/CovidMarkerInfoWindow';

const wrapperPositioningDiv = {
    position: 'absolute', 
    top: 0, 
    right: 0, 
    bottom: 0, 
    left: 0,
    height: 'calc(100% - 75px)',
    marginTop: 75,
}

var apiKey = "";

if (process.env.REACT_APP_VIALOOP_ENV === "production") {
    apiKey = process.env.REACT_APP_COVID_MAP_PRODUCTION_API_KEY;
} else {
    apiKey = process.env.REACT_APP_COVID_MAP_DEV_API_KEY;
}

const statePolygonType = "showStateSAHO";
const csvDataPolygonType = "csvDataPolygonType";

export class CovidAtlas extends React.Component {

    constructor(props) {
        super(props);

        this.auth0 = props.auth0;

        this.state = { 
            showStateSAHO: true,
            showCSVStates: false,

            infoWindowVisible: false, 
            infoWindowPosition: { lat: 38.8151211, lng: -96.7011451 },
            infoWindowData: null,

            //Note: this needs to be the first layer shown 
            previousLayer: statePolygonType,
        };

        this.sourceStateData = null; 
        this.statePolygons = null; 
        this.statePolygonRefs = null; 

        this.sourceCSVData = null; 
        this.csvPolygons = null; 
        this.csvRefs = null; 

        this.mapRef = null; 

        this.statesReady = false;
        this.csvReady = false; 
    }

    componentDidMount() {  

        this.auth0.getTokenSilently().then( (token) => {

            GetStateStayAtHomeOrders(token, (statesJSON) => {

                this.generatePolygons(statesJSON, statePolygonType);

                this.generatePolygons(statesJSON, csvDataPolygonType);

                //This call to setState is necessary to force the view to re-render when the states are downloaded for the first time (and remove overlay if done loading). 
                this.setState({testProperty: "dsdsfsd"});
            });

            GetIntersectedCountyEmergencyData(token, (callbackObj) => {

                const googleRef = this.props.google;
                const mapRef = this.mapRef; 
                var markers = callbackObj.locations.map(function(location, i) {

                    var marker = new googleRef.maps.Marker({
                        position: { lat: location.latitude, lng: location.longitude },
                        icon: MarkerIcon,
                    });

                    var infoWindow = new googleRef.maps.InfoWindow({
                        //generateHTMLString(name, address1, address2, city, state, zip, phoneNumber, locationNumber)
                        content: MarkerInfoWindowContent(location.name, location.address1, location.address2, location.city, location.state, location.zip, location.phone, location.number),
                    })

                    marker.addListener('click', function() {

                        infoWindow.open(mapRef.map, marker);
                    });

                    return marker;
                });

                new MarkerClusterer(this.mapRef.map, markers, { averageCenter: true, maxZoom: 8, styles: [{ anchorText: [20,27], height: 50, width: 50, url: ClusterIcon }]});
            });
        });
    }

    generatePolygons = (polygonsJSON, polygonType) => {

        //NOTE: polygonsJSON MUST BE an array of objects with the form [ { attributes: {...}, geometry: {...} }, ... ]

        if (polygonsJSON === null) {
            return; 
        }

        //polygons is a "dictionary" (there aren't really dictionaries in JS)
        var polygons = {};

        var refs = {};

        let key = 0; 

        function addRef(polygon)  { 
            //Type checking for safety 
            if (polygon === null || polygon === undefined) {
                return; 
            }

            refs[polygon.polygon.name] = polygon 
        };
        
        for (let i = 0; i < polygonsJSON.length; i++) {

            const rings = polygonsJSON[i].geometry.rings; 

            //Each ring represents a polygon 
            for (let j = 0; j < rings.length; j++) {

                const path = rings[j];

                let coords = [];

                for (var z = 0; z < path.length; z++) {
                    const coord = {
                        lat: path[z][1], 
                        lng: path[z][0]
                    };
                    coords.push(coord);
                }

                let fillColor = "";

                if (polygonType === csvDataPolygonType) {
                    fillColor = this.determinePolygonColor(polygonType, polygonsJSON[i].csvAttributes); 
                } else {
                    fillColor = this.determinePolygonColor(polygonType, polygonsJSON[i].attributes); 
                }

                let poly = <Polygon key={key} name={key} paths={coords} fillColor={fillColor} strokeWeight={1} ref={addRef} onClick={this.polygonClick} />;

                if (polygonType === csvDataPolygonType) {
                    polygons[key] = {
                        polygon: poly, 
                        attributes: polygonsJSON[i].csvAttributes, 
                    };
                } else {
                    polygons[key] = {
                        polygon: poly, 
                        attributes: polygonsJSON[i].attributes, 
                    };
                }

                key++;

            }
        }

        this.updateDataDependingOnPolygonType(polygonsJSON, polygons, refs, polygonType);
    }

    updateDataDependingOnPolygonType = (sourceData, polygons, refs, polygonType) => {

        //The below cannot be part of state because they are modified inside render() 

        switch(polygonType) {
            case statePolygonType:
                this.sourceStateData = sourceData;
                this.statePolygons = polygons;
                this.statePolygonRefs = refs;
                this.statesReady = true; 
            break;
            case csvDataPolygonType: 
                this.sourceCSVData = sourceData; 
                this.csvPolygons = polygons; 
                this.csvRefs = refs; 
                this.csvReady = true; 
            break; 
            default:
                console.log("Error: Should not be here. Check your polygonType: ");
                console.log(polygonType);
            break;
        }

    }

    determinePolygonColor = (polygonType, polygonAttributes) => {

        switch(polygonType) {
        case statePolygonType:
            if (polygonAttributes.YN === "Y") {
                return "red";
            } else {
                return "green";
            }
        //break; not needed here due to returns above 
        case csvDataPolygonType: 
            switch (polygonAttributes.reopeningStatus) {
                case "open": 
                    return "green";
                case "shut_down": 
                    return "red"; 
                case "reopening_soon": 
                    return "orange";
                case "partial_reopening": 
                    return "yellow";
                default: 
                    if (polygonAttributes.reopeningStatus !== "") {
                        console.log("TODO: Take care of this status covidAtlas.js 290: ");
                        console.log(polygonAttributes.reopeningStatus);
                    }
                    return "white";
            }
            //no break needed 
        default:
            console.log("Error: Should not be here. Check your polygonType: ");
            console.log(polygonType);
        break;
        }

    }

    polygonClick = (polygon, otherProp, randoProps) => {

        //Using the polygon name and appropriate dictionaries, get the coordinates for the polygon. Also get the data for the InfoWindow from this.countyPolygons or this.statePolygons.  
        //When the polygon is clicked update the info window state. 

        const center = this.calculatePolygonCenter(polygon.paths);

        var windowData; 

       if (this.state.showStateSAHO) {
            windowData = {
                data: this.statePolygons[polygon.name].attributes, 
                type: statePolygonType,
            };
        } else if (this.state.showCSVStates) {
            windowData = {
                data: this.csvPolygons[polygon.name].attributes,
                type: csvDataPolygonType
            }
        }

        this.setState({
            infoWindowVisible: true, 
            infoWindowPosition: center,
            infoWindowData: windowData,
        });

    }

    calculatePolygonCenter = (paths) => {

        var minX = paths[0].lng; 
        var minY = paths[0].lat; 
        var maxX = paths[0].lng;
        var maxY = paths[0].lat;

        for (var i = 0; i < paths.length; i++) {

            if (paths[i].lng < minX) {
                minX = paths[i].lng; 
            }

            if (paths[i].lat < minY) {
                minY = paths[i].lat;
            }

            if (paths[i].lng > maxX) {
                maxX = paths[i].lng; 
            }

            if (paths[i].lat > maxY) {
                maxY = paths[i].lat; 
            }

        }

        const centerX = (maxX + minX)/2; 
        const centerY = (maxY + minY)/2; 

        return { lat: centerY, lng: centerX };

    }

    layerChanged = (value) => {

        //Remove previous layer. Set proper state 
        // let refs; 
        // let polygons; 
        // let type; 
        let showStateSAHO = false;
        let showCSVStates = false; 
        let previousLayer;

        // switch(this.state.previousLayer) {
        //     case statePolygonType: 
        //         refs = this.statePolygonRefs;
        //         polygons = this.statePolygons;
        //         type = statePolygonType; 
        //     break; 
        //     case csvDataPolygonType: 
        //         refs = this.csvRefs;
        //         polygons = this.csvPolygons;
        //         type = csvDataPolygonType; 
        //     break;  
        //     default: 
        //     console.log("Should not be here. Check layer changed switch");
        //     break; 
        // }

        //TODO: JUST CHANGE THE POLYGON COLOR: 

        let thisRef = this; 

        //Note to future self: If the polygons are the same you can't remove them and then remake them as you are trying to do in mapRefsAndRemovePolygons 
        //I think either the Google library or the React wrapper library won't let you do it 
        //This is why we are referencing this.statePolygonRefs in all cases below 

        //TODO: Determine the proper state color based on the polygon attributes and which polygons we are supposed to be seeing right now 

        if (value === "sahoaa") {
            Object.keys(this.statePolygonRefs).map(function(key, index) {
                const attrs = thisRef.statePolygons[key].attributes;    
                const color = thisRef.determinePolygonColor(statePolygonType, attrs);
                thisRef.statePolygonRefs[key].polygon.setOptions({fillColor: color});
                return null; 
            });
        } else if (value === "csv") {
            Object.keys(this.statePolygonRefs).map(function(key, index) {
                const attrs = thisRef.csvPolygons[key].attributes;
                const color = thisRef.determinePolygonColor(csvDataPolygonType, attrs);
                thisRef.statePolygonRefs[key].polygon.setOptions({fillColor: color});
                return null; 
            });
        }

        if (value === "sahoaa") {
            showStateSAHO = true; 
            previousLayer = statePolygonType;
        } else if (value === "csv") {
            showCSVStates = true;
            previousLayer = csvDataPolygonType;
        }

        //The below is commented out when we only have state polygons 
        // this.mapRefsAndRemovePolygons(refs, polygons, type);

        this.setState({
            showStateSAHO: showStateSAHO,
            showCSVStates: showCSVStates,
            previousLayer: previousLayer,
        });

    }

    mapRefsAndRemovePolygons = (refs, polygons, type) => {

        console.log("REFS TO REMOVE: ", refs);

        let thisRef = this; 

        Object.keys(refs).map(function(key, index) {

            if (type === statePolygonType) {
                thisRef.statePolygonRefs[key].polygon.setMap(null);

                //By doing thisRef.statePolygonRefs[key].polygon = null; I somehow make the ref for the csvPolygon null. 

                //JUST CHANGE THE FILL COLOR OF THE POLYGON TO THE APPROPRIATE COLOR. STOP TRYING TO REMOVE POLYGONS - THIS LIBRARY TREATS THEM 
                //AS THE SAME BECAUSE THEY HAVE THE SAME SHAPE 

                thisRef.statePolygonRefs[key].polygon = null; 

                thisRef.statePolygonRefs[key] = null; 
            } else if (type === csvDataPolygonType) {
                thisRef.csvRefs[key].polygon.setMap(null);
                thisRef.csvRefs[key].polygon = null; 
                thisRef.csvRefs[key] = null; 
            }

            return null; 
        });
    }

    mapPolygonsToArray = (polyRef) => {

        var polygons = []; 

        Object.keys(polyRef).map(function(key, index) {
            polygons.push(polyRef[key].polygon);
            return null; 
        });

        return polygons;
    }

    renderOverlay = () => {

        var overlay; 

        //Don't show the overlay until everything is ready 
        if (this.statesReady && this.csvReady) {
            overlay = null; 
        } else {
            overlay = <LoadingOverlay />;  
        }

        return overlay; 
    }

    render() {

        var polygons = [];

        if (this.state.showStateSAHO && this.statePolygons !== null && this.statePolygons !== {}) {

            this.generatePolygons(this.sourceStateData, statePolygonType);

            polygons = this.mapPolygonsToArray(this.statePolygons);

        }  else if (this.state.showCSVStates) {

            this.generatePolygons(this.sourceCSVData, csvDataPolygonType);

            polygons = this.mapPolygonsToArray(this.csvPolygons);

            //Note: The below logs out all the correct polygons. But they are not showing up for some reason. 
            //console.log("THE POLYGONS TO PLOT ARE:::: ", polygons);
        }

        const legendData = {
            showStateSAHO: this.state.showStateSAHO,
            showCSVStates: this.state.showCSVStates,
        };

        var overlay = this.renderOverlay(); 

        return (
             <>
                { overlay }

                <CovidMapLayers 
                    onChange={this.layerChanged}
                />

                <CovidMapLegend 
                    legendData={legendData}
                />

                <div style={wrapperPositioningDiv} >

                    <Map
                        onReady={this.mapReady}
                        google={this.props.google}
                        zoom={5}
                        initialCenter={{
                        lat: 38.8151211,
                        lng: -96.7011451
                        }}
                        styles={MapStyle}
                        ref={(ref) => this.mapRef = ref}
                    >

                        { polygons }

                        <InfoWindow 
                            visible={this.state.infoWindowVisible}
                            position={this.state.infoWindowPosition}
                            onClose={ () => { this.setState({ infoWindowVisible: false }) } }
                        >
                            <CovidInfoWindow
                                windowData={this.state.infoWindowData} 
                            />
                        </InfoWindow>

                    </Map>
                
                </div>
                
            </>
          
        );
    }
}

export default GoogleApiWrapper({
    apiKey: apiKey
})(CovidAtlas);
