//token is auth0 token. locations are locations in the data table (in object format, not as a string). callback is the callback function to execute once this is all done. 
const GetCustomData = (token, locations, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/dataTables/customData", {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(locations)
    }).then( (response) => {
        response.text().then(function (text) {
            const obj = JSON.parse(text);

            callback(obj);

            /*This is what obj looks like: 
            {
                props: {
                    helloWorld: "string"
                    jhlhjkjh: "number"
                    jjkljkljlk: "string"
                }
            }
            */
        });
    }).catch( function(error) {
        console.log("Error loading data, GetCustomData.js 35: ");
        console.log(error);
    });
    
}

export default GetCustomData; 