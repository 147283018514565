import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import ViaLoopButton from '../components/ViaLoopButton';
import EnvCheck from '../models/EnvCheck';

const APIButton = (props) => {
  
  const { getTokenSilently } = useAuth0();

  const callApi = async () => {
      
    try {

      const { endpoint, body, method, callStart } = props;

      callStart();

      var token = await getTokenSilently();

      const baseURL = EnvCheck();

      const response = await fetch(baseURL + endpoint, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`
        }, 
        body: JSON.stringify(body)
      });

      var text = await response.text();

      if (text === "") {
        //This is an empty response. Just accept the 200 and move on
        props.callback();
        return;
      }

      const obj = JSON.parse(text);

      props.callback(obj);
        
    } catch (error) {
      console.error(error);
      props.callback(error);
      console.log(text);
    }
  };

  return (

    <ViaLoopButton 
      title={props.title}
      callback={ () => callApi()}
      isDisabled={props.isDisabled}
    />
  );
};

export default APIButton;
