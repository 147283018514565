import React from 'react';

class CovidMapLayers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: "sahoaa"
        };
    }

    filterBox = {
        backgroundColor: 'white',
        position: 'fixed', 
        top: 140, 
        left: 10, 
        width: 170, 
        height: 220, 
        zIndex: 5,
        borderRadius: 5,
        borderLeft: '2px solid #DB0A5B',
        borderTop: '2px solid #DB0A5B',
        borderRight: '2px solid #DB0A5B',
        display: 'flex',
        flexDirection: 'column',
    };

    titleStyle = {
        marginTop: 10,
        marginBottom: 2,
        fontWeight: 'bold',
        fontSize: 18,
    };

    radioButtonContainer = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 10,
    };

    labelStyle = {
        marginLeft: 10,
        textAlign: 'left',
    };

    radioStyle = {
        backgroundColor: 'blue',
        width: 50, 
        height: 50,
        marginLeft: 5,
    };

    onChange = e => {

        this.props.onChange(e.target.value);

        this.setState({
          value: e.target.value,
        });
        
    };

    render() {

        var stay = false; 
        var csv = false; 
        
        if (this.state.value === "sahoaa") {
            stay = true; 
        } else if (this.state.value === "csv") {
            csv = true; 
        }

        return (
            <div style={this.filterBox}>

                <p style={this.titleStyle}>Layers</p>
               
                <div style={this.radioButtonContainer}>
                    <input style={this.radioStyle} checked={stay} type="radio" id="sahoaa" name="layer" value="sahoaa" onChange={this.onChange} />
                    <label style={this.labelStyle} htmlFor="sahoaa">Stay at Home Orders and Advisories (Last update last month) </label>
                </div>

                <div style={this.radioButtonContainer}>
                    <input style={this.radioStyle} checked={csv} type="radio" id="csv" name="layer" value="csv" onChange={this.onChange} />
                    <label style={this.labelStyle} htmlFor="csv">State Reopening Status (Last update May 14th) </label>
                </div>

            </div>
        );
    }

}

export default CovidMapLayers; 