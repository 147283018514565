const GetIntersectedCountyEmergencyData = (token, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/covidAtlas/countyPublicHealthEmergencies?intersected=true", {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }, 
    }).then( (response) => {
        response.text().then(function (text) {

            try {
                const obj = JSON.parse(text);

                //TODO: THIS IS A HACK TO GET THE COUNTY DATA WORKING FOR NOW 
                obj.polygons = {};

                callback(obj); 

            } catch (e) {
                console.log("Error parsing data into JSON object. Check data format.");
                console.log("Error message: ", e);
                console.log("Response text: ", text);
            }

            /*This is what obj looks like: 
            [ 
                {
                    attributes: {}, 
                    geometry: {
                        rings: [ [ [-106.0213438593553, 44.993473423478],... ], ... ] //note the 0th ring contains the exterior shape. If there are additional rings they are holes in the geometry 
                    }
                }, ...
            ]
            */
        });
    }).catch( function(error) {
        console.log("Error loading data, columns.js 22: ");
        console.log(error);
    });
    
}

export default GetIntersectedCountyEmergencyData; 
