const UpdateSettings = (token, bodyObject, callback) => {

    var baseURL = ""; 

    if (process.env.REACT_APP_VIALOOP_ENV === "production") {
        baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
    } else {
        baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
    }

    fetch(baseURL + "v1/settings", {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(bodyObject),
    }).then( (response) => {
        response.text().then(function (text) {
            
            callback(text);
            
        });
    }).catch( function(error) {
        console.log("Error updating data, UpdateSettings.js 29: ");
        console.log(error);
    });
    
}

export default UpdateSettings; 