import React from 'react';
import Overlay from './Overlay';
import FormRow from './FormRowInput';
import ViaLoopButton from './ViaLoopButton';

class WorkSubmissionOverlay extends React.Component {

    checkRow = {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 'bold',
    }

    buttonContainer = {
        display: 'flex', 
        flexDirection: 'row',
    }

    render() {

        const { submitClicked, cancelClicked } = this.props;

        return (
            <Overlay
                width={600}
				height={500}
            >
                <h1>Submit Work Orders to Service Channel</h1>
                <h2>Location Numbers: 10, 11, 12, 13</h2>
                <div style={this.checkRow}>
                    <div>
                        Priority:
                    </div>
                    
                    <div>
                        <input type="radio" name="sla"></input> Emergency 
                    </div>
                    <div>
                        <input type="radio" name="sla"></input> Same Day
                    </div>
                    <div>
                        <input type="radio" name="sla"></input> Next Day 
                    </div>
                </div>
                <FormRow 
                    label="Site Contact"
                />
                <FormRow 
                    label="Trade"
                />
                 <FormRow 
                    label="DNE"
                />
                 <FormRow 
                    label="Notes"
                />

                <div style={this.buttonContainer}>
                    <ViaLoopButton 
                        callback={submitClicked}
                        title="Submit For All Locations"
                    />
                    <ViaLoopButton 
                        callback={cancelClicked}
                        title="Cancel"
                    />
                </div>
                
            </Overlay>
            

        );
    }
}

export default WorkSubmissionOverlay;