import React from 'react';
import { Input } from 'antd';
import ViaLoopButton from '../components/ViaLoopButton';
import AddColumnOverlay from '../components/AddColumnOverlay';
import EditableSortableTable from '../components/EditableSortableTable';
import DeleteColumnOverlay from '../components/DeleteColumnOverlay';
//import AddLocationOverlay from '../components/AddLocationOverlay';
import LoadingScreen from '../components/LoadingScreen';
import GetLocations from '../models/GetLocations';
import GetColumns from '../models/GetColumns';

class LocationsPage extends React.Component {

    //TODO: If no locations in the system, make the user able to add this table 

    constructor(props) {
        super(props);
        this.auth0 = props.auth0;

        this.state = { 
            isLoading: true,
            showAddColumnOverlay: false,  
            showDeleteColumnOverlay: false,
            showAddLocationOverlay: false
        };

    }

    componentDidMount() {

        //Set the columns to display on the table. This should match the JSON data 
        const columns = [
            {
                title: 'Number',
                dataIndex: 'number',
                key: 'number',
                sorter: true,
                editable: false,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                editable: false,
            },
            {
                title: 'Address',
                dataIndex: 'address1',
                key: 'address1',
                sorter: true,
                editable: false,
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                sorter: true, 
                editable: false,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                sorter: true,
                editable: false,
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                sorter: true,
                editable: false,
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                editable: false,
            },
        ];

        this.setState({ 
            columns: columns,
        });

        this.auth0.getTokenSilently().then( (token) => {
            GetLocations(token, this.fetchLocationsCallback);
            GetColumns(token, this.fetchColumnsCallback);
        });
    }

    fetchLocationsCallback = (tableRows) => {
        this.setState({
            locations: tableRows,
            allLocations: tableRows,
            isLoading: false
        });
    }

    fetchColumnsCallback = (columnsProps) => {

        if (columnsProps.props == null) {
            this.setState({customProps: columnsProps});
            return; 
        }

        var newColumns = this.state.columns;

        const newCols = columnsProps.props; 
        const entries = Object.entries(newCols);

        entries.forEach(function (item, index) {
            if (item.length > 1) {
                const colName = item[0]; 
                const colDataType = item[1];

                newColumns.push({
                    title: colName,
                    dataIndex: colName,
                    key: colName,
                    dataType: colDataType,
                    sorter: true,
                    editable: true,
                });
            }
        });

        this.setState({
            columns: newColumns, 
            customProps: columnsProps
        });

        //TODO: Append the custom columns to the standard columns in the right place. Before edit/delete, after core columns
        //columns.props takes the format: { "columnName": "columnType", "columnName2": "columnType2" }
    }
    
    headerRow = {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    titleText = {
        margin: 10,
    }

    searchBar = {
        width: 300,
        margin: 10,
        height: 40, 
    }

    buttonContainer = {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: -60,
    }

    addLocationCallback = () => {
        this.setState({ showAddLocationOverlay: true });
    }

    locationAdded = () => {
        this.setState({ showAddLocationOverlay: false });
    }

    cancelAddLocation = () => {
        this.setState({ showAddLocationOverlay: false });
    }

    addColumnCallback = () => {
        this.setState({ showAddColumnOverlay: true });
    }

    deleteColumnCallback = () => {
        this.setState({ showDeleteColumnOverlay: true });
    }

    cancelDeleteColumn = () => {
        this.setState({ showDeleteColumnOverlay: false });
    }

    saveColumnCompleted = (column) => {

        var columns = this.state.columns;

        columns.splice(columns.length - 1, 0, column);

        this.setState({ 
            columns: columns, 
            showAddColumnOverlay: false, 
        });
    }

    cancelColumnClicked = () => {
        this.setState({ showAddColumnOverlay: false });
    }

    confirmDeleteColumn = (columnName) => {

        var newColumns = [];
        var oldColumns = this.state.columns;

        oldColumns.forEach( function (item, index) {
            if ( item.title !== columnName ) {
                newColumns.push(item);
            }
        });

        this.setState({
            showDeleteColumnOverlay: false,
            columns: newColumns
        });
    }

    handleSearch = (value) => {

        //TODO: Make this handle custom columns 

        var whitespaceCheck = value; 

        whitespaceCheck = whitespaceCheck.trim(); 

        if (whitespaceCheck === "") {
            //Reset search
            var allLocations = this.state.allLocations;
            this.setState({ locations: allLocations });
            return; 
        }
        
        var filteredLocations = this.state.allLocations; 

        value = value.toLowerCase();

        filteredLocations = filteredLocations.filter( function(location) {

            if (location.address1.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.city.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.state.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.zip.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.name.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.number.toLowerCase().includes(value)) {
                return true; 
            }

            if (location.phone.toLowerCase().includes(value)) {
                return true; 
            }

            return false; 
        }); 
        
        this.setState({ 
            locations: filteredLocations,
        });

    }

    updateLocations = (newLocations) => {
        this.setState({ locations: newLocations });
    }

    render() {

        const { Search } = Input;

        if (this.state.isLoading) {
            return (<LoadingScreen />);
        } 

        //Make sure we only delete the custom columns, not anything that is hard coded 
        var columnsForDeletion = this.state.columns; 

        if (columnsForDeletion.length > 7) {
            columnsForDeletion = columnsForDeletion.slice(7);
        }

        return(
            <div>
                {/* {   
                    this.state.showAddLocationOverlay &&
                    <AddLocationOverlay 
                        addLocationPressed={this.locationAdded}
                        cancelPressed={this.cancelAddLocation}
                    />
                } */}

                {
                    this.state.showDeleteColumnOverlay &&
                    <DeleteColumnOverlay 
                        columns={columnsForDeletion}
                        confirmCallback={(columnName) => {this.confirmDeleteColumn(columnName)} }
                        cancelCallback={this.cancelDeleteColumn}
                    />
                }

                {
                    this.state.showAddColumnOverlay && 
                    <AddColumnOverlay 
                        saveClicked={ (newColumn) => this.saveColumnCompleted(newColumn) }
                        cancelClicked={this.cancelColumnClicked}
                        customProps={this.state.customProps}
                    />
                }

                <div style={this.headerRow}>
                    <h1 style={this.titleText}>Locations</h1>
                    <Search 
                        placeholder="input search text"
                        onSearch={value => this.handleSearch(value)}
                        style={this.searchBar}
                    />
                </div>

                <EditableSortableTable 
                    rows={this.state.locations} 
                    columns={this.state.columns}
                    updateRows={this.updateLocations}
                    isEditable={true}
                    auth0={this.auth0}
                />

                <div style={this.buttonContainer}>
                    <ViaLoopButton
                        title="+ Add Column"
                        callback={this.addColumnCallback}
                    />
                    <ViaLoopButton
                        title="- Delete Column"
                        callback={this.deleteColumnCallback}
                    />
                    {/* <ViaLoopButton
                        title="+ Add Location"
                        callback={this.addLocationCallback}
                    /> */}
                </div>

            </div>
        );
    }
}

export default LocationsPage;

