import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import { useAuth0 } from "./react-auth0-wrapper";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import CrisisMap from './pages/CrisisMap';
import TablePage from './pages/TablePage';
import LocationsPage from './pages/LocationsPage';
import withAuth0 from './views/withAuth0';
import SettingsPage from './pages/SettingsPage';
import CovidAtlas from './pages/CovidAtlas';
import CovidTables from './pages/CovidTables';
import 'antd/dist/antd.css'; 

function App() {

  const { loading } = useAuth0();

  const containerStyle = {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    width: '100%', 
    height: '100vh',
    backgroundColor: '#523178',
  }

  const loadingStyle = {
    color: 'white',
  }

  if (loading) {
    return (
      <div style={containerStyle}>
        <h1 style={loadingStyle}>Loading...</h1>
      </div>
    );
  }

  const appStyle = {
    height: '100vh',
    display: 'flex', 
    flexDirection: 'column',
  }

  return (
    <div className="App" style={appStyle}>
      <BrowserRouter>
        <header>
          <NavBar />
        </header>
        <Switch>
          <Route path="/" exact component={Login} />
          <PrivateRoute path="/settings" component={withAuth0(SettingsPage)} />
          <PrivateRoute path="/crisisMap" component={withAuth0(CrisisMap)} />
          <PrivateRoute path="/tableView" component={withAuth0(TablePage)} />
          <PrivateRoute path="/locations" component={withAuth0(LocationsPage)} /> 
          <PrivateRoute path="/covidAtlas" component={withAuth0(CovidAtlas)} /> 
          <PrivateRoute path="/covidTables" component={withAuth0(CovidTables)} /> 
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
