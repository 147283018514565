import React from 'react';
import { Switch, Icon, Popover } from 'antd';

function SwitchRow(props) {

    const { label, content, onChange, popoverTitle, email, sms, noteType } = props;

    const rowContainer = {
        display: 'flex', 
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10, 
        marginBottom: 10, 
    }

    const firstElement = {
        display: 'flex', 
        justifyContent: 'flex-start',
        width: '33%',
        textAlign: 'left'
    }

    const rowElement = {
        width: '33%',
    }

    const lastElement = {
        width: '33%', 
        display: 'flex', 
        justifyContent: 'flex-end'
    }

    const iconContainer = {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'center'
    }

    const contentText = (
        <div style={{width: 400}}>
            <p>{content}</p>
        </div>
    );
      

    return (
        <div style={rowContainer}> 

            <div style={firstElement}>{label}:</div>
            <div style={iconContainer}>
                <Popover content={contentText} title={popoverTitle}>
                    <Icon type="info-circle" />
                </Popover>
            </div>
            {/* 
                When we toggle the switch tell the callback functions which notification type it was and send them the new value 
                Pass "true" if the switch is an email switch. Pass "false" if the switch is a sms switch 
            */}
            <div style={rowElement}><Switch checked={email} onChange={ () => onChange(noteType, !email, true) } /></div>
            <div style={lastElement}><Switch checked={sms} onChange={ () => onChange(noteType, !sms, false) } /></div>
        </div>
    );
}

export default SwitchRow; 
