import React from 'react';
import BasicInfoRow from '../components/BasicInfoRow';
import SwitchRow from '../components/SwitchRow';
import GetSettings from '../models/GetSettings';
import UpdateSettings from '../models/UpdateSettings';
import LoadingScreen from '../components/LoadingScreen';

class SettingsPage extends React.Component {

    container = {
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
        width: 1000,
    }

    sectionHeader = {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10, 
        marginBottom: 10,
        borderBottom: '1px solid black', 
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
    }

    constructor(props) {
        super(props);

        this.auth0 = this.props.auth0;
        this.state = {
            isLoading: true, 
            settings: null,
        }
    }

    componentDidMount() {

        const cb = this.getSettingsCallback;

        this.auth0.getTokenSilently().then( (token) => {
            GetSettings(token, cb);
		});
       
    }

    settingsUpdatedCallback = (response) => {
        //Do something here if you wish...
    }

    formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');
        
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };
      
        return null
    };

    getSettingsCallback = (settings) => {

        let phoneNumber = this.formatPhoneNumber(settings.phone);

        settings.phone = phoneNumber;

        this.setState({
            settings: settings,
            isLoading: false
        });

        //console.log(settings);

        /*
        firstName: "Josh"
        lastName: "Hawthorne"
        email: "test@vialoop.com"
        phone: "5555555555"
        locationNearStormSurgeZoneEmail: true
        timeToHurricaneLessThanThreeDaysEmail: true
        locationHasGreaterThan50ChanceHFWEmail: true
        hurricaneWatchesEmail: true
        hurricaneWarningsEmail: true
        locationInForecastedHurricaneTrackEmail: true
        tornadoWatchEmail: true
        tornadoWarningEmail: true
        fireWeatherWatchEmail: true
        redFlagWarningEmail: true
        floodWatchEmail: true
        floodWarningEmail: true
        locationNearStormSurgeZoneSMS: false
        timeToHurricaneLessThanThreeDaysSMS: false
        locationHasGreaterThan50ChanceHFWSMS: false
        hurricaneWatchesSMS: true
        hurricaneWarningsSMS: false
        locationInForecastedHurricaneTrackSMS: false
        tornadoWatchSMS: false
        tornadoWarningSMS: false
        fireWeatherWatchSMS: false
        redFlagWarningSMS: false
        floodWatchSMS: false
        floodWarningSMS: false
        */
    }

    switchChanged = (noteType, newValue, isEmail) => {

        var newSettings = this.state.settings; 

        switch (noteType) {
            case "locationNearStormSurgeZone":
                if (isEmail) {
                    newSettings.locationNearStormSurgeZoneEmail = newValue;
                } else {
                    newSettings.locationNearStormSurgeZoneSMS = newValue;
                }
            break; 
            case "timeToHurricaneLessThanThreeDays": 
                if (isEmail) {
                    newSettings.timeToHurricaneLessThanThreeDaysEmail = newValue;
                } else {
                    newSettings.timeToHurricaneLessThanThreeDaysSMS = newValue;
                }
            break;
            case "locationHasGreaterThan50ChanceHFW":
                if (isEmail) {
                    newSettings.locationHasGreaterThan50ChanceHFWEmail = newValue;
                } else {
                    newSettings.locationHasGreaterThan50ChanceHFWSMS = newValue;
                }
            break;
            case "hurricaneWatches":
                if (isEmail) {
                    newSettings.hurricaneWatchesEmail = newValue;
                } else {
                    newSettings.hurricaneWatchesSMS = newValue;
                }
            break;
            case "hurricaneWarnings":
                if (isEmail) {
                    newSettings.hurricaneWarningsEmail = newValue;
                } else {
                    newSettings.hurricaneWarningsSMS = newValue;
                }
            break; 
            case "locationInForecastedHurricaneTrack":
                if (isEmail) {
                    newSettings.locationInForecastedHurricaneTrackEmail = newValue;
                } else {
                    newSettings.locationInForecastedHurricaneTrackSMS = newValue;
                }
            break;
            case "tornadoWatch": 
                if (isEmail) {
                    newSettings.tornadoWatchEmail = newValue;
                } else {
                    newSettings.tornadoWatchSMS = newValue;
                }
            break;
            case "tornadoWarning": 
                if (isEmail) {
                    newSettings.tornadoWarningEmail = newValue;
                } else {
                    newSettings.tornadoWarningSMS = newValue;
                }
            break;
            case "fireWeatherWatch": 
                if (isEmail) {
                    newSettings.fireWeatherWatchEmail = newValue;
                } else {
                    newSettings.fireWeatherWatchSMS = newValue;
                }
            break;
            case "redFlagWarning": 
                if (isEmail) {
                    newSettings.redFlagWarningEmail = newValue;
                } else {
                    newSettings.redFlagWarningSMS = newValue;
                }
            break;
            case "floodWatch": 
                if (isEmail) {
                    newSettings.floodWatchEmail = newValue;
                } else {
                    newSettings.floodWatchSMS = newValue;
                }
            break;
            case "floodWarning": 
                if (isEmail) {
                    newSettings.floodWarningEmail = newValue; 
                } else {
                    newSettings.floodWarningSMS = newValue; 
                }
            break;
            default: 
            //do nothing 
        }

        const sc = this.settingsUpdatedCallback;

        this.auth0.getTokenSilently().then( (token) => {
			UpdateSettings(token, newSettings, sc);
		});

        this.setState({settings: newSettings});
    }

    render() {

        if (this.state.isLoading) {
            return (<LoadingScreen />);
        } 

        const { firstName, lastName, email, phone, 
            locationNearStormSurgeZoneEmail, 
            timeToHurricaneLessThanThreeDaysEmail, 
            locationHasGreaterThan50ChanceHFWEmail, 
            hurricaneWatchesEmail,
            hurricaneWarningsEmail,
            locationInForecastedHurricaneTrackEmail,
            tornadoWatchEmail,
            tornadoWarningEmail,
            fireWeatherWatchEmail,
            redFlagWarningEmail,
            locationNearStormSurgeZoneSMS,
            timeToHurricaneLessThanThreeDaysSMS,
            locationHasGreaterThan50ChanceHFWSMS,
            hurricaneWatchesSMS,
            hurricaneWarningsSMS,
            locationInForecastedHurricaneTrackSMS,
            tornadoWatchSMS,
            tornadoWarningSMS,
            fireWeatherWatchSMS,
            redFlagWarningSMS } = this.state.settings;

        return(

            <div style={this.container}>

                <p style={this.sectionHeader}>Basic</p>

                <BasicInfoRow 
                    label="First Name"
                    value={firstName}
                    onChange={() => {}}
                />
                <BasicInfoRow  
                    label="Last Name"
                    value={lastName}
                    onChange={() => {}}
                />
                <BasicInfoRow  
                    label="Email"
                    value={email}
                    onChange={() => {}}
                />
                <BasicInfoRow     
                    label="Phone"
                    value={phone}
                    onChange={() => {}}
                />
                
                <p></p>
                <p></p>

                <div style={this.sectionHeader}>
                    <p>Notifications</p>
                    <p style={{marginLeft: -20}}>Email</p>
                    <p>Text/SMS</p>
                </div>
                
                
                <SwitchRow 
                    label="Location near storm surge zone" 
                    content={"Notifications occur when locations are within 1 mile of the “worst case” storm surge zone. These locations have an elevated risk of experiencing flooding relative to locations that are further from the surge zone.  The surge zone represents the 90 percentile of flooding depths derived from many different surge projections. "}
                    popoverTitle="Location near storm surge zone"
                    email={locationNearStormSurgeZoneEmail}
                    sms={locationNearStormSurgeZoneSMS}
                    noteType={"locationNearStormSurgeZone"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Time to Hurricane < 3 days" 
                    content={"Notifications occur when tropical force wind resulting from a tropical cyclone are expected to impact locations within the next 3 days.  The lead time represents the earliest reasonable arrival time and may occur after the forecasted arrival time. "}
                    popoverTitle={"Time to Hurricane < 3 days" }
                    email={timeToHurricaneLessThanThreeDaysEmail}
                    sms={timeToHurricaneLessThanThreeDaysSMS}
                    noteType={"timeToHurricaneLessThanThreeDays"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Location has > 0% chance hurricane force winds" 
                    content={"Notifications occur when locations have a chance of experiencing hurricane force winds within the next 5 days.  The chance of hurricane force wind is determined from aggregating the results of many different hurricane forecasts.   "}
                    popoverTitle={"Location has > 0% chance hurricane force winds"}
                    email={locationHasGreaterThan50ChanceHFWEmail}
                    sms={locationHasGreaterThan50ChanceHFWSMS}
                    noteType={"locationHasGreaterThan50ChanceHFW"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Hurricane watches"
                    content={"Hurricane Watches refer to National Weather Service Watches related to Tropical Cyclones.  Alerts include Tropical Storm Watches, Hurricane Watches, and Storm Surge Watches.  Watches related to Tropical Cyclones indicate that a cyclone poses a possible threat within 48 hours.  Notifications occur when locations are within areas with active Hurricane Watches."}
                    popoverTitle={"Hurricane watches"}
                    email={hurricaneWatchesEmail}
                    sms={hurricaneWatchesSMS} 
                    noteType={"hurricaneWatches"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Hurricane warnings"
                    content={"Hurricane Warnings refer to National Weather Service Warnings related to Tropical Cyclones.  Alerts include Tropical Storm Warnings, and Hurricane Warnings.  Warnings related to Tropical Cyclones indicate that severe winds associated with a tropical cyclone are expected within the next 36 hours. Notifications occur when locations are within areas with active Hurricane Warnings."}
                    popoverTitle={"Hurricane warnings"}
                    email={hurricaneWarningsEmail}
                    sms={hurricaneWarningsSMS} 
                    noteType={"hurricaneWarnings"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Location in forecasted hurricane track" 
                    content={"The forecast hurricane track forecast cone represents the probable track of the tropical cyclone center, where the most damaging winds occur.  Two-thirds of hurricane forecasts remain within the forecast cone.  Notifications occur when locations are within the track forecast cone."}
                    popoverTitle={"Location in forecasted hurricane track"}
                    email={locationInForecastedHurricaneTrackEmail}
                    sms={locationInForecastedHurricaneTrackSMS}
                    noteType={"locationInForecastedHurricaneTrack"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Tornado Watch" 
                    content={"A Tornado Watch is issued when severe thunderstorms and tornadoes are possible in and near the Watch area.  Notifications occur when locations are within areas with active Tornado Watches."}
                    popoverTitle={"Tornado Watch"}
                    email={tornadoWatchEmail}
                    sms={tornadoWatchSMS}
                    noteType={"tornadoWatch"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Tornado Warning"
                    content={"A Tornado Warning is issued when a Tornado is imminent.  Notifications occur when locations are within areas with active Tornado Warnings."}
                    popoverTitle={"Tornado Warning"} 
                    email={tornadoWarningEmail}
                    sms={tornadoWarningSMS}
                    noteType={"tornadoWarning"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Fire Weather Watch" 
                    content={"Fire Weather Watches are issued to alert officials of potentially dangerous fire weather conditions within the next 24 to 36 hours.  Notifications occur when locations are within areas with active Fire Weather Watches."}
                    popoverTitle={"Fire Weather Watch"}
                    email={fireWeatherWatchEmail}
                    sms={fireWeatherWatchSMS}
                    noteType={"fireWeatherWatch"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                <SwitchRow 
                    label="Red Flag Warning"
                    content={"Red Flag Warnings indicate that conditions are ideal for wild fire combustion and rapid spread.  Notifications occur when locations are within areas with active Red Flag Warnings."}
                    popoverTitle={"Red Flag Warning"}
                    email={redFlagWarningEmail}
                    sms={redFlagWarningSMS} 
                    noteType={"redFlagWarning"}
                    onChange={ (noteType, newValue, isEmail) => this.switchChanged(noteType, newValue, isEmail) }
                />
                
            </div>
        );
    }
}

export default SettingsPage; 
