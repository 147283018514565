import React from 'react'; 

class Overlay extends React.Component {

    overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        zIndex: 5,
    };

    containerStyle = {
        width: this.props.width, 
        height: this.props.height, 
        backgroundColor: 'white',
        position: 'absolute', 
        top: 100, 
        left: 0,
        right: 0, 
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 10,
        border: '2px solid #DB0A5B',
        borderRadius: 5,
        paddingLeft: 15,
        paddingRight: 15,
    };

    render() {
        return(
            <div style={this.overlayStyle}>
                <div style={this.containerStyle}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Overlay;