import React from 'react';
import EditableSortableTable from '../components/EditableSortableTable';
import WorkSubmissionOverlay from '../components/WorkSubmissionOverlay';
import { ExportToCsv } from 'export-to-csv';
import GetColumns from '../models/GetColumns';
import GetCustomData from '../models/GetCustomData';
import LoadingScreen from '../components/LoadingScreen';
import { Input } from 'antd';

class TablePage extends React.Component {

	headerStyle = {
		display: 'flex',
		justifyContent: 'space-between',
	}

	checkStyle = {
		display: 'flex',
		alignItems: 'center',
		margin: 10,
	}

	buttonStyle = {
		margin: 15,
		backgroundColor: '#DB0A5B',
		color: 'white',
		fontSize: 18,
		fontWeight: 'bold',
		width: 130,
		height: 50,
	}

	dropdownRow = {
		display: 'flex', 
		justifyContent: 'space-between',
		alignItems: 'center',
	}

	dropdownContainer = {
		margin: 15,
		display: 'flex',
		alignItems: 'center',
	}

	dropdownLink = {
		color: '#DB0A5B',
		fontSize: 18,
	}

	searchBar = {
        width: 300,
        margin: 10,
        height: 40, 
    }

	constructor(props) {
		super(props);

		this.auth0 = props.auth0;

		const columns = [
			{
				title: 'Number',
				dataIndex: 'number',
				key: 'number',
				sorter: true,
				editable: true, 
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				sorter: true,
				editable: true,
			},
			{
				title: 'Address',
				dataIndex: 'address1',
				key: 'address1',
				sorter: true,
				editable: true,
			},
			{
				title: 'City',
				dataIndex: 'city',
				key: 'city',
				sorter: true, 
				editable: true,
			},
			{
				title: 'State',
				dataIndex: 'state',
				key: 'state',
				sorter: true,
				editable: true,
			},
			{
				title: 'Zip',
				dataIndex: 'zip',
				key: 'zip',
				sorter: true,
				editable: true,
			},
			{
				title: 'Phone',
				dataIndex: 'phone',
				key: 'phone',
				sorter: true,
				editable: true,
			},
			{
				title: 'Tropical Force Wind Arrival Time',
				dataIndex: 'arrivalTimeTFW',
				key: 'arrivalTimeTFW',
				sorter: true,
			},
			{
				title: 'Prob of HF Winds',
				dataIndex: 'probabilityHFW',
				key: 'probabilityHFW',
				sorter: true,
			},
			{
				title: 'Forecast SS Depth',
				dataIndex: 'forecastedSSD',
				key: 'forecastedSSD',
				sorter: true,
			},
			{
				title: 'NWS Alert Type', 
				dataIndex: 'alertTypes', 
				key: 'alertTypes', 
				sorter: true,
			}
		];

		this.state = {
			columns: columns,
			showWorkSubmissionOverlay: false, 
			isLoading: true, 
		}
	}

	columnsRetrieved = (obj) => {

		if (obj === null || obj === undefined) {
			return;
		}

		if (obj.props === null || obj.props === undefined) {
			return;
		}

		const entries = Object.entries(obj.props);

		var newColumns = this.state.columns; 

		for (const [name, type] of entries) {

			const newColumn = {
				title: name, 
				dataIndex: name, 
				key: name, 
				sorter: true,
				dataType: type
			}; 

			newColumns.push(newColumn);

		}

		this.setState({columns: newColumns});

	}

	componentDidMount() {

		const columnsCallback = this.columnsRetrieved;

		const convertFunc = this.convertAPIDataToRows; 

		this.auth0.getTokenSilently().then( (token) => {

			GetColumns(token, columnsCallback);
			
			var baseURL = ""; 

			if (process.env.REACT_APP_VIALOOP_ENV === "production") {
				baseURL = process.env.REACT_APP_PRODUCTION_BASEURL;
			} else {
				baseURL = process.env.REACT_APP_DEVELOPMENT_BASEURL;
			}

			fetch(baseURL + "v1/dataTables", {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}, 
			}).then( (response) => {
				response.text().then(function (text) {

					try {
						const obj = JSON.parse(text);
						convertFunc(obj);
					} catch (e) {
						console.log("error parsing json");
						console.log(e);
						console.log(text);
					}
				});
			}).catch( function(error) {
				console.log("Error loading data, columns.js 22: ");
				console.log(error);
			});
        });
	}

	convertAPIDataToRows = (rowData) => {

		var tableRows = [];

        var i; 
        for (i = 0; i < rowData.length; i++) {

            // var utcSeconds = parseInt( rowData[i][8], 10);
            // var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
			// d.setUTCSeconds(utcSeconds);

			let timestamp = rowData[i].timestamp; 
			if (timestamp === "None") {
				rowData[i].timestamp = "NA"
			}
            
            var row = {
				id: rowData[i].locationId,
                key: rowData[i].locationId,
                number: rowData[i].locationNum,
                name: rowData[i].name,
                address1: rowData[i].uspsAddress, 
                city: rowData[i].uspsCity, 
                state: rowData[i].uspsState,
                zip: rowData[i].uspsZipcode,
				arrivalTimeTFW: rowData[i].timestamp,
                probabilityHFW: rowData[i].contour_value + "%",
                probabilityHFWInt:  parseInt( rowData[i].contour_value, 10),
                forecastedSSD: rowData[i].surge_value + " ft",
				forecastedSSDInt: parseInt( rowData[i].surge_value, 10),
				alertTypes: rowData[i].alertTypes,
            };
            
            tableRows.push(row);

		}
		
		this.setState({
            allLocations: tableRows,
			locations: tableRows
		});

		const gcdc = this.getCustomDataCallback; 

		this.auth0.getTokenSilently().then( (token) => {

			const postBody = {
				list: tableRows
			};

			GetCustomData(token, postBody, gcdc);
		});

	}

	getCustomDataCallback = (updatedLocations) => {
	
		//Only update the table if we actually have data 
		if (updatedLocations.list === null || updatedLocations.list === undefined) {
			this.setState({isLoading: false});
			return;
		}

		for ( var i = 0; i < updatedLocations.list.length; i++) {

			//Append the custom properties to the row if they exist 
			if (updatedLocations.list[i].customProperties !== null) {

				let customProperties = updatedLocations.list[i].customProperties; 
				let entries = Object.entries(customProperties);

				for (const [name, value] of entries) {
					updatedLocations.list[i][name] = value;
				}
			}

			//Append the custom properties to the row if they exist 
			//  if (jsonResponse[i].customProperties !== null) {

            //     let customProperties = jsonResponse[i].customProperties; 
            //     let entries = Object.entries(customProperties);

            //     for (const [name, value] of entries) {
            //         row[name] = value;
            //     }
            // }
		
		}

		this.setState({
			allLocations: updatedLocations.list,
			locations: updatedLocations.list,
			isLoading: false
		});
	}

	submitWorkClicked = () => {
		this.setState( { showWorkSubmissionOverlay: !this.state.showWorkSubmissionOverlay } );
	}

	submitFormClicked = () => {
		this.setState( { showWorkSubmissionOverlay: !this.state.showWorkSubmissionOverlay } );
	}

	exportDataClicked = () => {

		const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'Hurricane Data Export',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
		};
		
		//TODO: Make sure custom columns have at least null values for all rows 
         
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(this.state.locations);
	}

	workSubmissionCanceled = () => {
		this.setState( { showWorkSubmissionOverlay: !this.state.showWorkSubmissionOverlay } );
	}

	onRowSelected = (rows) => {
		
	}

	updateRows = (rows) => {
	
	}

	handleSearch = (value) => {

        var whitespaceCheck = value; 

        whitespaceCheck = whitespaceCheck.trim(); 

        if (whitespaceCheck === "") {
            //Reset search
            var allRows = this.state.allLocations;
            this.setState({ locations: allRows });
            return; 
        }
        
        var filteredLocations = this.state.allLocations; 

        value = value.toLowerCase();

        filteredLocations = filteredLocations.filter( function(location) {

            /*
                //1. Loop over key, value pairs for each location/row 
                if value for key contains search value, return true 
                if loop completes with no matches found, return false 
            */

            const keys = Object.keys(location);  

            let i; 
            for (i = 0; i < keys.length; i++) {

                const locationValue = location[keys[i]];

				//Ignore anything that is null, undefined, or an object 
                if (locationValue === null || locationValue === undefined || typeof locationValue === "object") {
                    continue;
                }

                if (typeof locationValue === "number") {
                    if (locationValue.toString().toLowerCase().includes(value)) {
                        return true; 
                    }
                    continue;
				}
				
				if (locationValue.toLowerCase().includes(value)) {
					return true; 
				}
            }

            return false; 
        }); 
        
        this.setState({ 
            locations: filteredLocations,
        });

    }

	render() {

		if (this.state.isLoading) {
            return (<LoadingScreen />);
		} 
		
		const { Search } = Input;

		return (
			<div>

				{ 
					this.state.showWorkSubmissionOverlay && 
					<WorkSubmissionOverlay 
						submitClicked={this.submitFormClicked} 
						cancelClicked={this.workSubmissionCanceled}
					/> 
				}

				<div style={this.dropdownRow}>
					<div style={this.dropdownContainer}>
						{/* <Dropdown overlay={menu}>
							<p style={this.dropdownLink}> 
								Select Data to Display <Icon type="down" />
							</p>
						</Dropdown> */}
					</div>

					<h1>Crisis Tables</h1>

					<div>
						<Search 
                        	placeholder="input search text"
                        	onSearch={value => this.handleSearch(value)}
                        	style={this.searchBar}
                    	/>

						<button style={this.buttonStyle} onClick={this.exportDataClicked}>Export Data</button>
					</div>
				</div>

				<EditableSortableTable 
                	rows={this.state.locations} 
                	columns={this.state.columns}
					updateRows={this.updateRows}
					//Note: uncommenting the below will make table rows selectable 
					//rowSelection={this.onRowSelected}
            	/>

			</div>
		);
	}
}

export default TablePage; 

