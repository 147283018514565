//Note: this must be in string format to work with the Google Maps JS Api 

function generateHTMLString(name, address1, address2, city, state, zip, phoneNumber, locationNumber) {

    const html =    `<div>
            <h2 style='text-align:center'>Location Information</h2>
            <p style='text-align:left'><strong>Name: </strong>`+ name +`</p>
            <p style='text-align:left'><strong>Address 1: </strong>` + address1 + `</p>
            <p style='text-align:left'><strong>Address 2: </strong>` + address2 + `</p>
            <p style='text-align:left'><strong>City: </strong>` + city + `</p>
            <p style='text-align:left'><strong>State: </strong>` + state + `</p>
            <p style='text-align:left'><strong>Zip: </strong>` + zip + `</p>
            <p style='text-align:left'><strong>Phone Number: </strong>` + phoneNumber + `</p>
            <p style='text-align:left'><strong>Location Number: </strong>` + locationNumber + `</p>
        </div>`

    return html 

}

export default generateHTMLString;